import React, { useState, useRef, useEffect } from "react";
import IconTriangle from "./mooncalendar/IconTriangle";
import Dropdown from "./Dropdown";

const H2Dropdown = ({ className, blockName, text, list, selectedData, setSelectedData }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  // Обработчик события клика на документе
  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };

  // Добавляем слушатель события при монтировании компонента
  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);


  return (
    <div className="flex relative" ref={dropdownRef}>
      <h2 className={`inline-flex mx-auto items-center justify-center text-white cursor-pointer ${className} `} onClick={handleDropdownClick} >
        {text}
        <IconTriangle className='w-6 h-6' />
      </h2>
      {isDropdownVisible && (
        <Dropdown blockName={blockName} list={list} selectedData={selectedData} setSelectedData={setSelectedData} setIsOpen={setIsDropdownVisible}/>
      )}
    </div>
  );
};

export default H2Dropdown;
