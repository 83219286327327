export const ROUTES = {
	INTRO: 'intro',
	CHOOSE: 'choose',
	FORECAST: 'forecast',
	COMPATIBILITY: 'compatibility',
	ABOUTSIGN: 'aboutsign',
	MOONCALENDAR: 'mooncalendar',
	BACK: 'back',
	SELECTBIRTH: 'selectbirth',
};

export const ZODIACS = {
	Aries: {
		icon_url: '/images/icons/aries.svg',
		illustrate_url: '/images/illustrates/aries.png',
		title: 'Овен',
	},
	Taurus: {
		icon_url: '/images/icons/taurus.svg',
		illustrate_url: '/images/illustrates/taurus.png',
		title: 'Телец',
	},
	Gemini: {
		icon_url: '/images/icons/gemini.svg',
		illustrate_url: '/images/illustrates/gemini.png',
		title: 'Близнецы',
	},
	Cancer: {
		icon_url: '/images/icons/cancer.svg',
		illustrate_url: '/images/illustrates/cancer.png',
		title: 'Рак',
	},
	Leo: {
		icon_url: '/images/icons/leo.svg',
		illustrate_url: '/images/illustrates/leo.png',
		title: 'Лев',
	},
	Libra: {
		icon_url: '/images/icons/libra.svg',
		illustrate_url: '/images/illustrates/libra.png',
		title: 'Весы',
	},
	Scorpio: {
		icon_url: '/images/icons/scorpio.svg',
		illustrate_url: '/images/illustrates/scorpio.png',
		title: 'Скорпион',
	},
	Sagittarius: {
		icon_url: '/images/icons/sagittarius.svg',
		illustrate_url: '/images/illustrates/sagittarius.png',
		title: 'Стрелец',
	},
	Capricornus: {
		icon_url: '/images/icons/capricornus.svg',
		illustrate_url: '/images/illustrates/capricornus.png',
		title: 'Козерог',
	},
	Aquarius: {
		icon_url: '/images/icons/aquarius.svg',
		illustrate_url: '/images/illustrates/aquarius.png',
		title: 'Водолей',
	},
	Pisces: {
		icon_url: '/images/icons/pisces.svg',
		illustrate_url: '/images/illustrates/pisces.png',
		title: 'Рыбы',
	},
	Virgo: {
		icon_url: '/images/icons/virgo.svg',
		illustrate_url: '/images/illustrates/virgo.png',
		title: 'Дева',
	},
};
