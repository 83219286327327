import "core-js/features/map";
import "core-js/features/set";
import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import App from "./App";


// Init VK  Mini App
bridge
	.send('VKWebAppInit')
	.then(() => {
		// Этот код выполнится после инициализации VK Mini Apps
		console.log('VK Mini Apps инициализировано.');
	})
	.catch((error) => {
		// Обработка ошибки
		console.error('Ошибка при инициализации VK Mini Apps:', error);
	});



ReactDOM.render(<App />, document.getElementById("root"));
if (process.env.NODE_ENV === "development") {
	//   import("./eruda").then(({ default: eruda }) => {}); //runtime download
}