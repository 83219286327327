import React, { useState } from 'react';

const Tabs = ({ className, tabLabels, activeTab, onTabClick }) => {
	return (
		<div className='forecast_period flex justify-between mt-4 pb-3 px-2 text-sm sm:text-base md:text-lg'>
			{tabLabels.map((tabLabel) => (
				<div
					key={tabLabel}
					className={`flex flex-shrink-0 text-center justify-center font-medium ${className ? className : ''} `}
					onClick={() => onTabClick(tabLabel)}
				>
					<span className={`px-1 sm:px-5 shadow-text ${activeTab === tabLabel ? 'active' : ''}`}>{tabLabel}</span>
				</div>
			))}
		</div>
	);
};

export default Tabs;
