import React from 'react';

import Zodiacs from './Zodiacs';
import ZodiacsItemViewSymbol from './ZodiacsItemViewSymbol';

const ZodiacListSymbol = ({ activePanel, changePanel, selectedZodiac, setSelectedZodiac, isMenuOpen, setMenuIsOpen }) => {
  return (
    <div className="">
       {Object.entries(Zodiacs).map(([key, zodiac]) => (
        // key === activePanel && (
          <ZodiacsItemViewSymbol key={"menu_zodiac_" + key}
            zodiacSign={key}
            zodiacInfo={zodiac}
            setSelectedZodiac={setSelectedZodiac}
            isMenuOpen={isMenuOpen}
            setMenuIsOpen={setMenuIsOpen}
            activePanel={activePanel}
            changePanel={changePanel}
            className={key === selectedZodiac ? 'active' : ''}
          />
        // ) 
      ))}

    </div>
  );
}

export default ZodiacListSymbol;