import React from 'react';
import IconCheck from '../img/IconCheck';

const IntroItem = ({ iconClass, title, description }) => {
  return (
    <li className="flex max-w-4/5 sm:w-4/5 mx-auto">
      <IconCheck className={`text-white w-7 sm:w-8 flex-shrink-0 ${iconClass}`} />
      <div className="ml-4">
        <p className="font-semibold text-lg sm:text-lg md:text-xl leading-6 shadow-text">{title}</p>
        <p className="text-stone-400 shadow-text">{description}</p>
      </div>
    </li>
  );
}

export default IntroItem;