import React from "react";
import ZodiacListSymbol from "../zodiacs/ZodiacsListSymbol";
import { ROUTES } from "../../config";
import { Icon28Cancel } from '@vkontakte/icons';
import { VKWebAppShare } from '../app/VKBridgeFunctions'

const MenuHeader = ({ className, isMenuOpen, setMenuIsOpen, activePanel, changePanel, selectedZodiac, setSelectedZodiac, setShowPopupDateBirth, setShowPopupNotificationSettings }) => {

  return (
    <div className="menuHeader w-full">
      <div className="flex justify-between w-full h-10">
        <div className="flex text-base font-medium sm:text-lg md:text-xl items-center ">
          Меню
        </div>
        <div
          className="flex items-center cursor-pointer"
          onClick={() => setMenuIsOpen(!isMenuOpen)}
          onTouchEnd={() => setMenuIsOpen(!isMenuOpen)}
        >
          <Icon28Cancel fill="#ffffff" />
        </div>
      </div>
      <div className="text-stone-300 mt-5 mb-1">Выбранный знак зодиака</div>
      <div className="menuListZodiacs">
        <ZodiacListSymbol activePanel={activePanel} changePanel={changePanel} selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} isMenuOpen={isMenuOpen} setMenuIsOpen={setMenuIsOpen} />
      </div>
      <div className="mt-5">
        <div className="cursor-pointer pb-2" onClick={() => { setShowPopupDateBirth(true); setMenuIsOpen(false); }} >Определить знак</div>
        <div className="cursor-pointer pb-2" onClick={() => { changePanel(ROUTES.MOONCALENDAR); setMenuIsOpen(false); }} >Календарь стрижек</div>
        <div className="cursor-pointer pb-6" onClick={() => { VKWebAppShare(); setMenuIsOpen(false); }} >Поделиться приложением</div>
        <div className="cursor-pointer pb-6" onClick={() => { setShowPopupNotificationSettings(true); setMenuIsOpen(false); }} >Настройка уведомлений</div>

        {/* <a className="pb-2" href="mailto:al.al.golovin@gmail.com">Написать разработчику</a> */}
      </div>
    </div>
  );
};

export default MenuHeader;
