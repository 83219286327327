import React from 'react';

const ZodiacsItemСompatibilitySign = ({ active, typeSign, setActiveTypeSign }) => {
  const handleClick = () => {
    setActiveTypeSign(typeSign);
  }

  return (
    <div className={`bg-[#000D3A] text-slate-300 sign ${active}`} onClick={handleClick}>
      Выберите ниже второй знак
    </div>
  );
};

export default ZodiacsItemСompatibilitySign;