import { Snackbar } from "@vkontakte/vkui";
import { Icon24ErrorCircleFillRed } from '@vkontakte/icons';

const SnackbarApp = ({ className, setShowSnackbar, text }) => {
   const closeSnackbar = () => {
      setShowSnackbar(false);
   };

   return (
      <>
         <Snackbar
            layout="vertical"
            onClose={closeSnackbar}
            before={<Icon24ErrorCircleFillRed fill="var(--vkui--color_status_error)" />}
         >
            {text}
         </Snackbar>
      </>
   );
}

export default SnackbarApp;
