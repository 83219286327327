import React from 'react';
const Zodiacs = {
	aries: {
		title: 'Овен',
		url_icon: '/images/zodiacs/icons/zodiac_icons_sprite.svg#aquarius',
		url_illustrate: '/images/zodiacs/illustrates/aries.svg',
		url_symbol: '/images/zodiacs/symbols/aries.svg',
		date_start: '21.03',
		date_end: '20.04',
		element: 'Огонь'
	},
	taurus: {
		title: 'Телец',
		url_icon: '/images/zodiacs/icons/taurus.svg',
		url_illustrate: '/images/zodiacs/illustrates/taurus.svg',
		url_symbol: '/images/zodiacs/symbols/taurus.svg',
		date_start: '21.04',
		date_end: '20.05',
		element: 'Земля'
	},
	gemini: {
		title: 'Близнецы',
		url_icon: '/images/zodiacs/icons/gemini.svg',
		url_illustrate: '/images/zodiacs/illustrates/gemini.svg',
		url_symbol: '/images/zodiacs/symbols/gemini.svg',
		date_start: '21.05',
		date_end: '21.06',
		element: 'Воздух'
	},
	cancer: {
		title: 'Рак',
		url_icon: '/images/zodiacs/icons/cancer.svg',
		url_illustrate: '/images/zodiacs/illustrates/cancer.svg',
		url_symbol: '/images/zodiacs/symbols/cancer.svg',
		date_start: '22.06',
		date_end: '22.07',
		element: 'Вода'
	},
	leo: {
		title: 'Лев',
		url_icon: '/images/zodiacs/icons/leo.svg',
		url_illustrate: '/images/zodiacs/illustrates/leo.svg',
		url_symbol: '/images/zodiacs/symbols/leo.svg',
		date_start: '23.07',
		date_end: '22.08',
		element: 'Огонь'
	},
	virgo: {
		title: 'Дева',
		url_icon: '/images/zodiacs/icons/virgo.svg',
		url_illustrate: '/images/zodiacs/illustrates/virgo.svg',
		url_symbol: '/images/zodiacs/symbols/virgo.svg',
		date_start: '23.08',
		date_end: '23.09',
		element: 'Земля'
	},
	libra: {
		title: 'Весы',
		url_icon: '/images/zodiacs/icons/libra.svg',
		url_illustrate: '/images/zodiacs/illustrates/libra.svg',
		url_symbol: '/images/zodiacs/symbols/libra.svg',
		date_start: '24.09',
		date_end: '23.10',
		element: 'Воздух'
	},
	scorpio: {
		title: 'Скорпион',
		url_icon: '/images/zodiacs/icons/scorpio.svg',
		url_illustrate: '/images/zodiacs/illustrates/scorpio.svg',
		url_symbol: '/images/zodiacs/symbols/scorpio.svg',
		date_start: '24.10',
		date_end: '22.11',
		element: 'Вода'
	},
	sagittarius: {
		title: 'Стрелец',
		url_icon: '/images/zodiacs/icons/sagittarius.svg',
		url_illustrate: '/images/zodiacs/illustrates/sagittarius.svg',
		url_symbol: '/images/zodiacs/symbols/sagittarius.svg',
		date_start: '23.11',
		date_end: '21.12',
		element: 'Огонь'
	},
	capricornus: {
		title: 'Козерог',
		url_icon: '/images/zodiacs/icons/capricornus.svg',
		url_illustrate: '/images/zodiacs/illustrates/capricornus.svg',
		url_symbol: '/images/zodiacs/symbols/capricornus.svg',
		date_start: '22.12',
		date_end: '19.01',
		element: 'Земля'
	},
	aquarius: {
		title: 'Водолей',
		url_icon: '/images/zodiacs/icons/aquarius.svg',
		url_illustrate: '/images/zodiacs/illustrates/aquarius.svg',
		url_symbol: '/images/zodiacs/symbols/aquarius.svg',
		date_start: '20.01',
		date_end: '19.02',
		element: 'Воздух'
	},
	pisces: {
		title: 'Рыбы',
		url_icon: '/images/zodiacs/icons/pisces.svg',
		url_illustrate: '/images/zodiacs/illustrates/pisces.svg',
		url_symbol: '/images/zodiacs/symbols/pisces.svg',
		date_start: '20.02',
		date_end: '20.03',
		element: 'Вода'
	},
};

export default Zodiacs;
