import React, {Fragment} from "react";
import HTMLReactParser from 'html-react-parser';

const H1 = ({ className, text, isHtml }) => {
  return (
    <div className="flex mb-4 justify-center items-center">
      <h1 className={`text-white ${className ? className : ''}`}>
      {isHtml
				?
				  HTMLReactParser(text)
				: text}
      </h1>
    </div>
  );
};

export default H1;
