import React, { useState, useEffect } from 'react';
import Zodiacs from './Zodiacs';

const ZodiacsItemViewIcon = ({ zodiac, sign, setSelectedZodiac, className, svgZodiacIcons, active }) => {
	return (
		<div
			className={`flex flex-col items-center px-3 py-1 sign ${active ? active : ''}`}
			{...(setSelectedZodiac ? { onClick: () => setSelectedZodiac(sign) } : {})}
		>
			<svg className={`h-16 sm:h-18 w-full mb-2 ${className ? className : ''}`}>
				<title>{zodiac.title}</title>
				{svgZodiacIcons 
					? (
					<use xlinkHref={`#${getKeyByValue(Zodiacs, zodiac)}`}></use>
					) : (
					<use xlinkHref={`/images/zodiacs/icons/zodiac_icons_sprite.svg#${getKeyByValue(Zodiacs, zodiac)}`}></use>
					)
				}
			</svg>
			<p className='text-center shadow-text'>{zodiac.title}</p>
		</div >
	);
};

function getKeyByValue(object, value) {
	return Object.keys(object).find(key => object[key] === value);
}

const ZodiacIcon = ({ svg, id, className }) => {
	if (!svg) {
		return null;
	}
	else
		return (
			<>
				{svg}
				<svg className={`h-16 sm:h-18 w-full mb-2 ${className || ''}`}>
					<title>{id}</title>
					<use href={`#${id}`}></use>
				</svg>
			</>
		);
};

const SvgIcon = ({ svgCode, id, className }) => {
	const createMarkup = () => ({ __html: svgCode });

	return (
		<svg className={`h-16 sm:h-18 w-full mb-2 ${className || ''}`} dangerouslySetInnerHTML={createMarkup()} />
	);
};

export default ZodiacsItemViewIcon;
