import React, { useState } from "react";
import ListViewItem from "./ListViewItem";

const Dropdown = ({ blockName, list, selectedData, setSelectedData, typeData, setIsOpen }) => {

  return (
    <div className="dropdown-list absolute top-full left-1/2 transform -translate-x-1/2 w-auto bg-black rounded-lg mt-1 shadow-lg">
      <div>
        {blockName === 'moonСalendarDropdown'
          ? (
            Object.entries(list).map(([year, months]) => (
              Object.entries(months).map(([month, data]) => (
                <ListViewItem key={`list_${blockName}_${year}_${month}`}
                  itemKey={[{ year }, { month }]}
                  itemText={data['title']}
                  setSelectedData={setSelectedData}
                  setIsOpen={setIsOpen}
                  className={selectedData && selectedData[0]['moonCalendarYear'] == year && selectedData[1]['moonCalendarMonth'] == month ? 'active' : ''}
                />
              ))
            ))
          ) : (
            Object.entries(list).map(([key, value]) => (
              <ListViewItem key={`list_${blockName}_${key}`}
                itemKey={key}
                itemText={value}
                setSelectedData={setSelectedData}
                closeDropdown={closeDropdown}
                typeData={typeData}
                className={key === selectedData ? 'active' : ''}
              />
            ))
          )
        }


      </div>
    </div>
  );
};

export default Dropdown;
