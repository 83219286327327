import React, { useState, useRef, useEffect } from "react";
import IconTriangle from "./mooncalendar/IconTriangle";
import ZodiacList from "./zodiacs/ZodiacsList";
import Zodiacs from "./zodiacs/Zodiacs";

const H2 = ({ className, text, selectedData, setSelectedData }) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const dropdownRef = useRef(null);

  const handleDropdownClick = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  // Обработчик события клика на документе
  const handleDocumentClick = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropdownVisible(false);
    }
  };

  // Добавляем слушатель события при монтировании компонента
  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);


  return (
    <div className="flex relative" ref={dropdownRef}>
      <h2 className={`inline-flex mx-auto items-center justify-cente cursor-pointer ${className} `} onClick={handleDropdownClick} >
        {text}
        <IconTriangle className='w-6 h-6' />
      </h2>
      {isDropdownVisible && (
        <div className="dropdown-list absolute top-full left-1/2 transform -translate-x-1/2 w-auto bg-black rounded-lg mt-1 shadow-lg">
          <ZodiacList list={Zodiacs} selectedData={selectedData} setSelectedData={setSelectedData} setIsOpen={setIsDropdownVisible} />
        </div>
      )}
    </div>
  );
};

export default H2;
