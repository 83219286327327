import React, { useState, useEffect } from 'react';

import { Div } from '@vkontakte/vkui';
import { DateInput } from '@vkontakte/vkui';
import { getZodiacSignByDate, convertDateStringToDate } from './app/getServerData';
import { setToLocaleStorage, getFromLocalStorage } from './app/getAndSetToStorage';
import Zodiacs from './zodiacs/Zodiacs';
import Btn from './Button';
import { ROUTES } from '../config';
import InfoBlock from './InfoBlock';
import ZodiacsItemViewIcon from './zodiacs/ZodiacsItemViewIcon';
import bridge from "@vkontakte/vk-bridge";

const SelectBirth = ({ className, changeContent, setSelectedZodiac, setPopupShow, svgZodiacIcons }) => {
	const [selectedData, setSelectedData] = useState(null);
	const [dateBirthLocalStorage, setDateBirthLocalStorage] = useState(null);
	const [selectedZodiacByDate, setSelectedZodiacByDate] = useState(null);
	const keyStorageDateBirth = 'dateBirthLocalStorage';

	const disableFuture = true;
	const calendarPlacement = 'bottom';

	const [vkUser, setVKUser] = useState(null);

	useEffect(() => {
		if (selectedData && selectedData != dateBirthLocalStorage) {
			const zodiac = getZodiacSignByDate(selectedData, Zodiacs);
			if (zodiac) {
				setSelectedZodiacByDate(zodiac);
				setDateBirthLocalStorage(selectedData);
				setToLocaleStorage(keyStorageDateBirth, selectedData);
			}
		}
		else if(selectedData === null) {
			let dateBirthLocal = getFromLocalStorage(keyStorageDateBirth);
			if(dateBirthLocal) {
				let dateLocal = new Date(dateBirthLocal);
				setDateBirthLocalStorage(dateLocal);
				setSelectedData(dateLocal);
				const zodiac = getZodiacSignByDate(dateLocal, Zodiacs);
				if(zodiac) {
					setSelectedZodiacByDate(zodiac);
				}

			}
		}
	}, [selectedData]);

	useEffect(() => {
		async function fetchUser() {
			try {
				const user = await bridge.send('VKWebAppGetUserInfo');
				setVKUser(user);
			}
			catch(error) {
				console.log(`Не удалось получить данные пользователя VK. Ошибка: ${error}`);
			}
		}
		if(vkUser === null)
			fetchUser();
		else if(vkUser && selectedData === null) {
			try {
				if(vkUser && vkUser.bdate) {
					console.log(vkUser.bdate);
					const bdate = convertDateStringToDate(vkUser.bdate);
					if(bdate) {
						console.log(`Подгрузили из VK дату рождения пользователя: ${bdate}`);
						setSelectedData(bdate);
					}
					else {
						console.log(`Не удалось получить дату рождения пользователя VK.`);
					}
					
				}
			}
			catch(error) {
				console.log(`Не удалось получить дату рождения пользователя VK. Ошибка: ${error}`);
			}
		}
	}, [selectedData, vkUser]);

	const handleDateChange = (date) => {
		if (date)
			setSelectedData(date);
	}

	const handleClick = () => {
		if(setPopupShow) setPopupShow(false);
		setSelectedZodiac(selectedZodiacByDate);
		changeContent(ROUTES.FORECAST);
	}

	return (
		<>
			<InfoBlock className='mt-1' content={
				<div>
					<Div className='p-0'>
						<div className='mb-2'>Укажите вашу дату рождения</div>
						<DateInput
							value={selectedData}
							onChange={handleDateChange}
							onHeaderChange={handleDateChange}
							disableFuture={disableFuture}
							calendarPlacement={calendarPlacement}
						/>
						<div className='mt-6 font-bold'>Знак зодиака</div>
						{selectedZodiacByDate
							? <div>
								{/* <ZodiacsItemForecastIllustrate className='' zodiac={Zodiacs[selectedZodiacByDate]} /> */}
								{/* <h2 className='text-center'>{Zodiacs[selectedZodiacByDate].title}</h2> */}
								<ZodiacsItemViewIcon className='h-16 md:h-20' zodiac={Zodiacs[selectedZodiacByDate]} sign={selectedZodiacByDate}svgZodiacIcons={svgZodiacIcons} />
								
							</div>
							: 'Не определен. Укажите дату рождения выше.'
						}
						<style>
							{`.vkuiPopper {z-index: 999 !important;}`}
						</style>
					</Div>
				</div>
			} />
			<Btn
				className={'mb-6' + (className ? `${className}` : '')}
				disabled={!selectedZodiacByDate && 'disabled' }
				text={selectedZodiacByDate ? 'Выбрать знак' : 'Укажите дату рождения'}
				handleClick={handleClick}
			/>
		</>

	);
};
export default SelectBirth;
