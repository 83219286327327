import React from 'react';
import { ROUTES } from '../../config';

const ZodiacsItemViewSymbol = ({ activePanel, changePanel, zodiacSign, zodiacInfo, className, setSelectedZodiac, isMenuOpen, setMenuIsOpen }) => {
	const handleClick = (sign) => {
		setSelectedZodiac(sign);
		setMenuIsOpen(!isMenuOpen);
		if(activePanel && activePanel == ROUTES.MOONCALENDAR) {
			changePanel(ROUTES.FORECAST);
		}
	};

	return (
		<div className={`flex flex-auto item-center menu-zodiac-item ${className}`} onClick={() => handleClick(zodiacSign)} >
			{/* <img
				src={zodiacInfo.url_symbol}
				className='w-6 h-6'
				
				alt={zodiacInfo.title}
			/> */}
			<svg className={`w-6 h-6 ${className ? className : ''}`}>
				<title>{zodiacInfo.title}</title>
				<use xlinkHref={`/images/zodiacs/symbols/zodiac_symbols_sprite.svg#${zodiacSign}`}></use>
			</svg>
			<p className='ml-2'>{zodiacInfo.title}</p>
		</div>
	);
};

export default ZodiacsItemViewSymbol;
