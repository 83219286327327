import React, { useState, useEffect } from 'react';

import InfoBlock from '../components/InfoBlock';
import Zodiacs from '../components/zodiacs/Zodiacs';
import ZodiacsItemViewIcon from '../components/zodiacs/ZodiacsItemViewIcon';
import ZodiacsGrid from '../components/zodiacs/ZodiacsGrid';
import ZodiacsItemСompatibilitySign from '../components/zodiacs/ZodiacsItemСompatibilitySign';
import Btn from '../components/Button';
import { getFromLocalStorage, setToLocaleStorage } from '../components/app/getAndSetToStorage';
import PopupCompatibility from '../components/compatibility/PopupCompatibility';
import { getCompatibilityData } from '../components/app/getServerData';

const Compatibility = ({ selectedZodiac, setSelectedZodiac, svgZodiacIcons, setErrorZodiacBD, isOnline }) => {
	const typeSignMain = 'основной';
	const typeSignCompatibitity = 'совместимости';
	const [activeTypeSign, setActiveTypeSign] = useState(typeSignCompatibitity);

	const [selectedZodiacCompatibility, setSelectedZodiacCompatibility] = useState(null);
	const [zodiacCompatibilityLocalStorage, setZodiacCompatibilityLocalStorage] = useState(null);
	const keyStorageSelectedZodiacCompatibility = 'selectedZodiacCompatibility';

	const [compatibilityDescription, setCompatibilityDescription] = useState('Не удалось получить совместимость знаков с сервера');
	const setErrorDescription = () => {
		setCompatibilityDescription('Не удалось получить совместимость знаков с сервера');
	}

	const [loading, setLoading] = useState(true);
	const [popupIsOpen, setPopupIsOpen] = useState(false);

	useEffect(() => {
		console.log('Активирована панель для выбора знака:', activeTypeSign);
	}, [activeTypeSign]);

	// Функционал получения и сохранения знака совместимости
	useEffect(() => {
		if (selectedZodiacCompatibility === null) {
			let zodiacLocal = getFromLocalStorage(keyStorageSelectedZodiacCompatibility);
			if (zodiacLocal) {
				setSelectedZodiacCompatibility(zodiacLocal);
				setZodiacCompatibilityLocalStorage(zodiacLocal);
			}
		}
		else if (selectedZodiacCompatibility && (selectedZodiacCompatibility != zodiacCompatibilityLocalStorage)) {
			setToLocaleStorage(keyStorageSelectedZodiacCompatibility, selectedZodiacCompatibility);
			setZodiacCompatibilityLocalStorage(selectedZodiacCompatibility);
		}

		if (selectedZodiacCompatibility)
			console.log('Назначен знак совместимости: ' + selectedZodiacCompatibility);
	}, [zodiacCompatibilityLocalStorage, selectedZodiacCompatibility]);

	useEffect(() => {
		if (popupIsOpen) {
			if(isOnline)
				getCompatibilityData(setLoading, setErrorZodiacBD, setErrorDescription, selectedZodiac, selectedZodiacCompatibility, setCompatibilityDescription);
			else
				setErrorZodiacBD(true);
		}
		
	}, [popupIsOpen, isOnline]);

	return (
		<>
			<h1 className='font-medium text-center text-white inline-block shadow-text'>
				Совместимость
			</h1>
			<div className='flex items-center justify-center mt-3 mb-3 px-3 py-1 compatibility-type-sign'>
				<ZodiacsItemViewIcon active={activeTypeSign == typeSignMain && 'active'} zodiac={Zodiacs[selectedZodiac]} sign={typeSignMain} setSelectedZodiac={setActiveTypeSign} svgZodiacIcons={svgZodiacIcons} />
				<div className='flex w-[40px] justify-center items-center px-4 h-full'>И</div>
				{selectedZodiacCompatibility
					? <ZodiacsItemViewIcon active={activeTypeSign == typeSignCompatibitity && 'active'} zodiac={Zodiacs[selectedZodiacCompatibility]} sign={typeSignCompatibitity} setSelectedZodiac={setActiveTypeSign} svgZodiacIcons={svgZodiacIcons} />
					: <ZodiacsItemСompatibilitySign active={`${activeTypeSign === typeSignCompatibitity ? 'active' : ''}`} typeSign={typeSignCompatibitity} setActiveTypeSign={setActiveTypeSign} />
				}
			</div>
			<InfoBlock className='mt-1 px-3 flex-grow overflow-auto rounded-bl-none rounded-br-none' content={
				<>
					{activeTypeSign == typeSignMain
						? <ZodiacsGrid selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} svgZodiacIcons={svgZodiacIcons} />
						: <ZodiacsGrid selectedZodiac={selectedZodiacCompatibility} setSelectedZodiac={setSelectedZodiacCompatibility} svgZodiacIcons={svgZodiacIcons} />
					}
					<div className='fixed-btn'>
						<Btn
							className={'mt-4 mb-4'}
							disabled={!selectedZodiacCompatibility && 'disabled'}
							text={selectedZodiacCompatibility ? 'Показать совместимость' : 'Выберите второй знак'}
							handleClick={() => setPopupIsOpen(true)}
						/>
					</div>
				</>
			} />
			{popupIsOpen && (<PopupCompatibility loading={loading} setLoading={setLoading} setPopupIsOpen={setPopupIsOpen} selectedZodiac={selectedZodiac} selectedZodiacCompatibility={selectedZodiacCompatibility} compatibilityDescription={compatibilityDescription} setCompatibilityDescription={setCompatibilityDescription} />)}
		</>
	);
}
export default Compatibility;
