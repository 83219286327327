import React from 'react';

const ListViewItem = ({ className, itemKey, itemText, setSelectedData, setIsOpen }) => {
	const handleClick = () => {
		if (Array.isArray(setSelectedData)) {
			for (let i = 0; i < setSelectedData.length; i++) {
				let data = Object.values(itemKey[i])[0];
				Object.values(setSelectedData[i])[0](data);
			}
		}
		else {
			setSelectedData(itemKey);
		}

		setIsOpen(false);
	}
	return (
		<div className={`sign z-50 dropdown-item ${className}`} onClick={() => handleClick()}  >
			{itemText}
		</div>
	);
};

export default ListViewItem;
