import React from 'react';

import ZodiacsItemViewList from './ZodiacsItemViewList';

const ZodiacList = ({ selectedData, setSelectedData, list, setIsOpen }) => {
  return (
    <div className="">
       {Object.entries(list).map(([key, zodiac]) => (
        // key === activePanel && (
          <ZodiacsItemViewList key={"list_zodiac_" + key}
            zodiacSign={key}
            zodiacInfo={zodiac}
            setSelectedZodiac={setSelectedData}
            setIsOpen={setIsOpen}
            className={key === selectedData ? 'active' : ''}
          />
        // ) 
      ))}

    </div>
  );
}

export default ZodiacList;