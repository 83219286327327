import React, { useState, useEffect } from 'react';

import H1 from '../components/H1';
import ZodiacsGrid from '../components/zodiacs/ZodiacsGrid';
import Btn from '../components/Button';
import SnackbarApp from '../components/SnackbarApp';
import SelectBirth from '../components/SelectBirth';
import Tabs from '../components/Tabs';

const Choose = ({ selectedZodiac, setSelectedZodiac, changeContent, nextButtonClick, errorZodiacBD, setErrorZodiacBD, svgZodiacIcons }) => {
	const [activeTab, setActiveTab] = useState('Из списка');
	const tabLabels = ['Из списка', 'По дате рождения'];

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	// Функционал получения и сохранения основного знака с подгрузкой данных с сервера
	useEffect(() => {
		console.log(`Активная вкладка: ${activeTab}`);
	}, [activeTab]);

	return (
		<>
			<H1 className='mt-[70px] shadow-text' text='Выберите знак зодиака' />
			<Tabs
				tabLabels={tabLabels}
				activeTab={activeTab}
				onTabClick={handleTabClick}
				className='flex-1 shadow-text'
			/>
			{activeTab == 'Из списка' && (
				<>
					<ZodiacsGrid className='mt-2 infoBlock rounded-xl' selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} svgZodiacIcons={svgZodiacIcons} />
					<Btn
						disabled={!selectedZodiac && 'disabled'}
						text={selectedZodiac ? 'Продолжить' : 'Не выбран знак'}
						handleClick={nextButtonClick}
					/>
				</>
			)}
			{activeTab == 'По дате рождения' && (
				<SelectBirth selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} changeContent={changeContent} svgZodiacIcons={svgZodiacIcons} className='mt-8' />
			)}
			{errorZodiacBD && (<SnackbarApp setShowSnackbar={setErrorZodiacBD} text="Не удалось подгрузить данные с сервера." />)}
			{/* {popupZodiacSignByDateIsOpen && (<Popup closePopup={popupZodiacSignByDateClose} content={<SelectBirth />} />)} */}
		</>


	);
};
export default Choose;
