import React, { useEffect, useRef } from 'react';
import HTMLReactParser from 'html-react-parser';
import { ROUTES } from '../config';

const InfoBlock = ({ className, content, elems, isHtml, activePanel }) => {
	const ref = useRef(null);

	useEffect(() => {
		// При обновлении контента прокручиваем в начало
		if (activePanel && activePanel == ROUTES.MOONCALENDAR && ref.current) {
		  ref.current.scrollTop = 0;
		}
	 }, [content]);

	return (
		<div
			ref={ref}
			className={`infoBlock ${className ? className : ''}`}
		>
			{isHtml
				?
				  HTMLReactParser(content)
				: content}
			{elems ? elems : ''}
		</div>
	);
};

export default InfoBlock;
