// Импортируем bridge из VK Bridge API
import bridge from '@vkontakte/vk-bridge';
import { ROUTES } from '../../config';

// Попытка извлечь выбранный знак зодиака из Local Storage
export function getFromLocalStorage(key) {
   try {
      const data = localStorage.getItem(key);
      if (data) {
         if(key != 'svgZodiacIcons')
            console.log('Получено из Local Storage ' + key + ':', data);
         return data;
      }
      else
         return null;
   } catch (error) {
      console.error('Ошибка при загрузке из Local Storage:', error);
   }
};

// Попытка сохранить выбранный знак зодиака в Local Storage
export function setToLocaleStorage(key, sign) {
   try {
      localStorage.setItem(key, sign);
      if(key != 'svgZodiacIcons')
         console.log('Сохранено в Local Storage ' + key + ':', sign);
   } catch (error) {
      console.error('Ошибка при сохранении в Local Storage:', error);
      // Обработка ошибки, например, вывод сообщения об ошибке
   }
};

export const getFromVKStorage = async (key, activeContent, changeContent, setSelectedZodiac, setZodiacLocalStorage, setZodiacVKStorage) => {
   // Используем метод VKWebAppStorageGet для чтения данных
   console.log(`active panel: ${activeContent}`);
   const urlParams = new URLSearchParams(window.location.search);
	const vkPlatform = urlParams.get('vk_platform');
   if(!vkPlatform && activeContent == ROUTES.BACK) 
      changeContent(ROUTES.INTRO);

   await bridge.send('VKWebAppStorageGet', {
      keys: [key],
   }).then((data) => {
      if (data.keys) {
         try {
            const value = data.keys[0].value;
            if (value) {
               const valueFormatted = value.split('"').join('');;
               setSelectedZodiac(valueFormatted);
               setZodiacLocalStorage(valueFormatted);
               setZodiacVKStorage(valueFormatted);
               if (activeContent == ROUTES.BACK)
                  changeContent(ROUTES.FORECAST);
            }
            else {
               if (activeContent == ROUTES.BACK) {
                  changeContent(ROUTES.INTRO);
               }   
            }
         } catch (error) {
            if (activeContent == ROUTES.BACK)
               changeContent(ROUTES.INTRO);
               
            console.log(`Ошибка при чтении данных из VK Storage ${key}: ${error}`);
         }
      }
   }).catch((error) => {
      console.log('2');
      if (activeContent == ROUTES.BACK)
         changeContent(ROUTES.INTRO);
      console.log(`Ошибка при чтении данных из VK Storage ${key}: ${error}`);
   });

}


export const setToVKStorage = async (key, value) => {
   bridge.send('VKWebAppStorageSet', {
      key: key,
      value: JSON.stringify(value)
   }).then((data) => {
      if (data.result) {
         console.log(`Данные успешно сохранены в VK Storage ${key}: ${value} `);
      }
   }).catch((error) => {
      console.log(`Ошибка при сохранении данных в VK Storage ${key}: `, error);
   });
}

export const getFromVKStorageTest = async (key, activeContent, changeContent, setSelectedZodiac, setZodiacLocalStorage, setZodiacVKStorage) => {
   // Используем метод VKWebAppStorageGet для чтения данных
   await bridge.send('VKWebAppStorageGet', {
      keys: [key],
   }).then((data) => {
      if (data.keys) {
         try {
            const value = data.keys[0].value;
            if (value) {
               const valueFormatted = value.replace(/"/g, '');
               setZodiacVKStorage(valueFormatted);
            }
         } catch (error) {
            if (activeContent == ROUTES.BACK)
               changeContent(ROUTES.INTRO);
            console.log(`Ошибка при чтении данных из VK Storage ${key}: ${error}`);
         }
      }
   }).catch((error) => {
      if (activeContent == ROUTES.BACK)
         changeContent(ROUTES.INTRO);
      console.log(`Ошибка при чтении данных из VK Storage ${key}: ${error}`);
   });
}

export const getSVGIcon = async (iconPath) => {
   try {
      const response = await fetch(iconPath);
      if (!response.ok) {
         throw new Error(`Failed to fetch file: ${response.statusText}`);
      }

      const text = await response.text();
      return text;
   } catch (error) {
      console.error(`Error reading file: ${error.message}`);
      throw error;
   }
}

// Функция для преобразования строки SVG-кода в JSX
export const createSvgFromCode = (svgCode, id = '', className = '') => {
   return (
     <svg
       id={id}
       className={className}
       xmlns="http://www.w3.org/2000/svg"
       dangerouslySetInnerHTML={{ __html: svgCode }}
     />
   );
};