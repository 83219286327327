import React, { useRef } from 'react';

const Btn = ({ text, className, handleClick, disabled, r }) => {
	
	return (
		<div ref={r && (r)} className={`flex w-full mb-4 mt-4 ${className ? className : 'mt-8'} `}>
			<button
				className={`${disabled ? 'disabled' : ''}`}
				onClick={handleClick}
			>
				<div className='flex justify-center items-center justify-items-center align-middle'>{text}</div>
				
			</button>
		</div>
	);
};

export default Btn;
