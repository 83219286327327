import React from "react";
import FooterItem from "./FooterItem";
import { ROUTES } from "../../config";

const Items = {
  forecast: {
    title: "Прогноз",
    url: "/images/footer/footer_icons_sprite.svg#forecast",
    contentID: ROUTES.FORECAST
  },
  compatibility: {
    title: "Совместимость",
    url: "/images/footer/footer_icons_sprite.svg#compatibility",
    contentID: ROUTES.COMPATIBILITY
  },
  aboutsign: {
    title: "О знаке",
    url: "/images/footer/footer_icons_sprite.svg#sign",
    contentID: ROUTES.ABOUTSIGN
  },
  mooncalendar: {
    title: "Стрижка",
    url: "/images/footer/footer_icons_sprite.svg#moon",
    contentID: ROUTES.MOONCALENDAR
  },
};

const Footer = ({ activeContent, changeContent }) => {
  return (
    <footer>
      {Object.entries(Items).map(([key, icon]) => {
        const isActive = key === activeContent;
        return (
          <FooterItem
            key={"footer_item" + key}
            iconUrl={icon.url}
            iconTitle={icon.title}
            handleClick={changeContent}
            active={isActive ? "active" : ""}
            contentID={icon.contentID}
          />
        );
      })}
    </footer>
  );
};

export default Footer;
