import React, { useState, useRef, useEffect } from 'react';
import Btn from './Button';
import { Icon24ShareExternalOutline } from '@vkontakte/icons';
import { VKWebAppShowWallPostBox, VKWebAppShowStoryBox, VKWebAppShare } from './app/VKBridgeFunctions';

const ShareButton = ({ shareText }) => {
   const handleTelegramShareClick = () => {
      const baseUrl = 'https://t.me/share?url=+';
      const encodedText = encodeURIComponent(shareText);
      const shareLink = `${baseUrl}&text=${encodedText}%0A%0AГороскоп%20на%20сегодня,%20завтра,%20неделю,%20месяц,%20год:%0Ahttps://vk.com/app51692231`;
      // Открывает ссылку в новой вкладке/окне
      window.open(shareLink, '_blank');
   };

   const handleVKShareClick = () => {
      const baseUrl = 'https://vk.com/share.php?url=https://vk.com/app51692231';
      const encodedText = encodeURIComponent(shareText);
      const shareLink = `${baseUrl}&comment=${encodedText}`;
      // Открывает ссылку в новой вкладке/окне
      window.open(shareLink, '_blank');
   };

   const [isDropdownVisible, setIsDropdownVisible] = useState(false);
   const dropdownRef = useRef(null);

   const handleDropdownClick = () => {
      setIsDropdownVisible(!isDropdownVisible);
   };

   // Обработчик события клика на документе
   const handleDocumentClick = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
         setIsDropdownVisible(false);
      }
   };

   // Добавляем слушатель события при монтировании компонента
   useEffect(() => {
      document.addEventListener('click', handleDocumentClick);
      return () => {
         document.removeEventListener('click', handleDocumentClick);
      };
   }, []);

   return (
      <Btn r={dropdownRef} className='button_small relative'
         text={<><Icon24ShareExternalOutline /><span className='ml-2'>Поделиться прогнозом</span>
            {isDropdownVisible && (
               <div className="dropdown-list absolute bottom-16 w-full left-1/2 transform -translate-x-1/2 bg-black rounded-lg shadow-lg">
                  <div className='dropdown-item py-3' onClick={() => VKWebAppShare()}>Поделиться приложением</div>
                  <div className='dropdown-item py-3' onClick={() => VKWebAppShowStoryBox(shareText)}>Добавить в сторис</div>
                  <div className='dropdown-item py-3' onClick={() => VKWebAppShowWallPostBox(shareText)}>Разместить на стене</div>
               </div>
            )}
         </>
         }
         handleClick={handleDropdownClick} 
      />
   );
};

export default ShareButton;
