import React from 'react';

const ZodiacsItemForecastIllustrate = ({ className, zodiac }) => {
	return (
		<div className='zodiac-illustrate'>
			<img
				src={zodiac.url_illustrate}
				className={`w-auto mx-auto mb-1 h-32 ${className ? className : ''}`}
			/>
		</div>
	);
};

export default ZodiacsItemForecastIllustrate;
