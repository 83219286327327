import React from 'react';

const ZodiacsItemViewList = ({ zodiacSign, zodiacInfo, setSelectedZodiac, setIsOpen, className }) => {
	const handleClick = () => {
		setSelectedZodiac(zodiacSign);
		setIsOpen(false);
	}
	return (
		<div
			className={`sign z-50 dropdown-item ${className}`}
			onClick={() => handleClick()}
		>
			{zodiacInfo.title}
		</div>
	);
};

export default ZodiacsItemViewList;
