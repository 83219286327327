import React from 'react';

import Zodiacs from './Zodiacs';
import ZodiacsItemViewIcon from './ZodiacsItemViewIcon';

const ZodiacsGrid = ({ className, selectedZodiac, setSelectedZodiac, svgZodiacIcons }) => {
	return (
		<div className={` mt-1 px-3 py-6 grid grid-cols-3 gap-4 zodiac_grid w-full h-auto overflow-auto ${className ? className : ''}`}>
			{Object.entries(Zodiacs).map(([key, zodiac]) => (
				<ZodiacsItemViewIcon
					key={'zodiac_' + key}
					zodiac={zodiac}
					setSelectedZodiac={setSelectedZodiac}
					active={key === selectedZodiac && 'active'}
          		sign={key}
					svgZodiacIcons={svgZodiacIcons}
				/>
			))}
		</div>
	);
};

export default ZodiacsGrid;
