import React from "react";
import { Panel, PanelHeader, Group, Cell, Div, Button } from "@vkontakte/vkui";
import MenuHeader from "./MenuHeader";
// import MenuZodiacList from "./MenuZodiacsList";

const MenuApp = ({ className, activePanel, changePanel, isMenuOpen, setMenuIsOpen, selectedZodiac, setSelectedZodiac, setShowPopupDateBirth, setShowPopupNotificationSettings }) => {
  return (
    <div
      id="menu"
      className={`flex absolute top-0 w-100 left-0 w-full h-full bg-opacity-50 bg-black z-10 ${isMenuOpen ? "show" : "hidden"}  ${className ? className : ''}`}
    >
      <div className="menu_content flex px-3 py-4 bg-black w-64 overflow-auto">
        <MenuHeader setMenuIsOpen={setMenuIsOpen} isMenuOpen={isMenuOpen} activePanel={activePanel} changePanel={changePanel} selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} setShowPopupDateBirth={setShowPopupDateBirth} setShowPopupNotificationSettings={setShowPopupNotificationSettings} />
        {/* <MenuZodiacs /> */}
      </div>
      <div
        className="flex-1 menu_bg cursor-pointer"
        onClick={() => setMenuIsOpen(!isMenuOpen)}
      ></div>
    </div>
  );
};

export default MenuApp;
