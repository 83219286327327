import React from 'react';
import { Panel, Spinner, Div } from "@vkontakte/vkui";
import '@vkontakte/vkui/dist/vkui.css';

import InfoBlock from '../components/InfoBlock';
import H1 from '../components/H1';
import Zodiacs from '../components/zodiacs/Zodiacs';
import ZodiacsItemForecastIllustrate from '../components/zodiacs/ZodiacsItemForecastIllustrate';
import H2 from '../components/H2';
import ZodiacDopInfo from '../components/zodiacs/ZodiacsDopInfo';

const AboutSign = ({ selectedZodiac, setSelectedZodiac, loading, zodiacDescription }) => {
	return (
		<>
			<H1 className='shadow-text' text='О знаке' />
			<ZodiacsItemForecastIllustrate className='' zodiac={Zodiacs[selectedZodiac]} />
			<H2 className='shadow-text' text={Zodiacs[selectedZodiac].title} selectedData={selectedZodiac} setSelectedData={setSelectedZodiac} />
			<ZodiacDopInfo className='shadow-text' zodiac={Zodiacs[selectedZodiac]} />
			<InfoBlock className='description flex-1 rounded-bl-none rounded-br-none' content={loading ? <Spinner size="large" /> : zodiacDescription} isHtml={loading ? false : true} />
		</>
	);
};
export default AboutSign;
