import React from "react";

const FooterItem = ({ iconTitle, iconUrl, contentID, active, handleClick }) => {
  return (
    <div className={` flex-1 flex flex-col justify-center align-middle footer-item ${active} `} onClick={() => { handleClick(contentID) }} >
      <div className="flex justify-center">
        {/* <img className="flex h-8 mb-1" src={iconUrl} alt={iconTitle}/> */}
        <svg className={`h-8 w-full mb-1`}>
          <title>{iconTitle}</title>
          <use xlinkHref={iconUrl}></use>
        </svg>
      </div>
      <div className="flex justify-center text-sm md:text-base">{iconTitle}</div>
    </div>
  );
};

export default FooterItem;
