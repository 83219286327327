import React, { useEffect } from 'react';
import {
	View,
	AdaptivityProvider,
	ViewWidth,
	AppRoot,
	ConfigProvider,
	SplitLayout,
	SplitCol,
} from '@vkontakte/vkui';
import '@vkontakte/vkui/dist/vkui.css';

import Main from './pages/Main';

import './css/App.css';

const App = () => {
	return (
		<ConfigProvider appearance='dark'>
			<AdaptivityProvider viewWidth={ViewWidth.DESKTOP}>
				<AppRoot mode='partial'>
					<SplitLayout>
						<SplitCol>
							<View activePanel='main'>
								<Main id='main' />
							</View>
						</SplitCol>
					</SplitLayout>
				</AppRoot>
			</AdaptivityProvider>
		</ConfigProvider>
	);
};

export default App;
