import React from 'react';
import InfoBlock from '../components/InfoBlock';
import { Spinner } from '@vkontakte/vkui';

const Back = ({ id }) => {
	return (
		<>
			<InfoBlock className='description flex-1 rounded-bl-none rounded-br-none bg-transparent p-0 mt-0' content={<Spinner size="large" />} />
		</>

	);
};
export default Back;
