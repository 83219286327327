import React, { useState, useEffect } from 'react';

import { Panel, Div } from '@vkontakte/vkui';

import Btn from '../components/Button';
import H1 from '../components/H1';
import IntroItem from '../components/intro/IntroItem';
import SnackbarApp from '../components/SnackbarApp';

const Intro = ({ nextButtonClick, errorZodiacBD, setErrorZodiacBD }) => {
	return (
		<div>
			<H1 className='mt-[70px] shadow-text' text={`Добро пожаловать<br/>в&nbsp;Мой&nbsp;Гороскоп`} isHtml={true} />
			<ul className='space-y-8 sm:space-y-9 md:space-y-10 mx-auto mt-3 py-3 px-3'>
				<IntroItem
					title='Астрологический прогноз'
					description='На сегодня, завтра, неделю, год.'
				/>
				<IntroItem
					iconClass='bg-teal'
					title='Совместимость знаков'
					description='Узнаете вашу совместимость с другими знаками.'
				/>
				<IntroItem
					title='Подробное описание знака'
					description='Характер, сильные и слабые стороны.'
				/>
			</ul>
			<Btn text='Продолжить' className='mt-8' handleClick={nextButtonClick} />
			{errorZodiacBD && (<SnackbarApp setShowSnackbar={setErrorZodiacBD} text="Не удалось подгрузить данные с сервера." />)}
		</div>

	);
};
export default Intro;
