import React from 'react';
import { Icon36Cancel } from '@vkontakte/icons';
import Btn from './Button';

const Popup = ({ setPopupShow, content }) => {
   return (
      <div className="popup-container flex fixed w-full h-full bg-black bg-opacity-70">
         <div className='flex flex-1 hover:cursor-pointer' onClick={() => setPopupShow(false)}></div>
         <div className="my-popup-container flex flex-col items-center screen w-full px-0 justify-center py-0">
            <div className='flex grow w-full hover:cursor-pointer' onClick={() => setPopupShow(false)}></div>
            <div className='popup'>
               <div className="flex justify-between w-full h-10">
                  <div className="flex items-center cursor-pointer" onClick={() => setPopupShow(false)}><Icon36Cancel fill="#fff" /></div>
               </div>
               <div className="flex flex-col w-full text-base font-medium sm:text-lg md:text-xl items-center">{content}</div>
            </div>
            <div className='flex grow w-full hover:cursor-pointer' onClick={() => setPopupShow(false)}></div>
         </div>
         <div className='flex flex-1 hover:cursor-pointer' onClick={() => setPopupShow(false)}></div>
      </div>

   )
}

export default Popup;