import React, {useState} from "react";
import MenuApp from "./MenuApp";

const Header = ({ activeContent, changeContent, selectedZodiac, setSelectedZodiac, setShowPopupDateBirth, setShowPopupNotificationSettings }) => {
  const [isMenuOpen, setMenuIsOpen] = useState(false);
  return (
    <>
      <header>
        <div className="space-y-2 cursor-pointer" onClick={() => setMenuIsOpen(!isMenuOpen)} >
          <span className="block w-8 h-0.5 bg-white"></span>
          <span className="block w-8 h-0.5 bg-white"></span>
          <span className="block w-8 h-0.5 bg-white"></span>
        </div>
      </header>
      { <MenuApp isMenuOpen={isMenuOpen} setMenuIsOpen={setMenuIsOpen} activePanel={activeContent} changePanel={changeContent} selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} setShowPopupDateBirth={setShowPopupDateBirth} setShowPopupNotificationSettings={setShowPopupNotificationSettings} /> }
    </>
  );
};

export default Header;
