import axios from 'axios';

export async function getZodiacData(setLoading, setErrorZodiacBD, setErrorDescription, selectedZodiac, setForecastToday, setForecastTomorrow, setForecastWeek, setForecastMonth, setForecastYear, setZodiacDescription, setZodiacDataFromBD) {
   setLoading(true);
   try {
      const response = await axios.get(`https://horoscope.mgk-it.ru/api/get_forecast/${selectedZodiac}`);
      if (response.data.status === 'ok') {
         const data = response.data.message;
         setZodiacDataFromBD(data);
         const formattedData = getFormattedData(data);
         // Обновляем данные в приложении
         setZodiacsInfo(formattedData, setForecastToday, setForecastTomorrow, setForecastWeek, setForecastMonth, setForecastYear, setZodiacDescription);
         console.log('Для знака ' + selectedZodiac + ' подгружены данные с сервера: ' + response.data.processingTime);
         setErrorZodiacBD(false);
         setLoading(false);
      }
      else {
         console.log(response.data.status, response.data.message);
         setErrorZodiacBD(true);
         setLoading(false);
         setErrorDescription();
      }
   } catch (error) {
      setErrorZodiacBD(true);
      console.log(error);
      setLoading(false);
      setErrorDescription();
   }
}

export async function getCompatibilityData(setLoading, setErrorZodiacBD, setErrorDescription, selectedZodiac, selectedZodiacCompatibility, setCompatibilityDescription) {
   setLoading(true);
   const url = `https://horoscope.mgk-it.ru/api/get_compatibility/${selectedZodiac}?sign2=${selectedZodiacCompatibility}`;
   try {
      const response = await axios.get(url);
      if (response.data.status === 'ok') {
         const data = response.data.message;
         let needTitlesInText = true;
         const formattedData = parseTextToHTML(data, needTitlesInText);
         setCompatibilityDescription(formattedData);
         // Обновляем данные в приложении
         console.log(`Получена с сервера совместимость знаков ${selectedZodiac} и ${selectedZodiacCompatibility}:`, response.data.processingTime);
         setErrorZodiacBD(false);
         setLoading(false);
      }
      else {
         setErrorZodiacBD(true);
         setCompatibilityDescription(response.data.message);
         setLoading(false);
         setErrorDescription();
      }
   } catch (error) {
      console.log(error);
      setErrorZodiacBD(true);
      setLoading(false);
      setErrorDescription();
   }
}

// Установка данных о зодиаке в переменные приложения
function setZodiacsInfo(message, setForecastToday, setForecastTomorrow, setForecastWeek, setForecastMonth, setForecastYear, setZodiacDescription) {
   for (let key in message) {
      if (message.hasOwnProperty(key)) {
         switch (key) {
            case 'today':
               setForecastToday(message[key]);
               break;
            case 'tomorrow':
               setForecastTomorrow(message[key]);
               break;
            case 'week':
               setForecastWeek(message[key]);
               break;
            case 'month':
               setForecastMonth(message[key]);
               break;
            case 'year':
               setForecastYear(message[key]);
               break;
            case 'description':
               setZodiacDescription(message[key]);
               break;
            default:
               break;
         }
      }
   }
}

export async function getMoonCalendarData(setLoading, setErrorZodiacBD, setMoonCalendarList) {
   setLoading(true);
   const url = `https://horoscope.mgk-it.ru/api/get_mooncalendar/`;
   try {
      const response = await axios.get(url);
      if (response.data.status === 'ok') {
         const data = response.data.message;
         const formattedData = getFormattedDataMoonCalendar(data, true);
         setMoonCalendarList(formattedData);
         // Обновляем данные в приложении
         console.log('Загружены данные лунного календаря с сервера', response.data.processingTime);
         setErrorZodiacBD(false);
         setLoading(false);
      }
      else {
         console.log(response.data.status, response.data.message);
         // setMoonCalendarDescription(response.data.message);
         setErrorZodiacBD(true);
         setLoading(false);
      }
   } catch (error) {
      console.log(error);
      setErrorZodiacBD(true);
      setLoading(false);
   }
}

// Форматирование полученных данных с сервера для отображения в приложении
function getFormattedData(message, needTitlesInText) {
   let temp_data = {};
   let needTitles;

   for (let key in message) {
      needTitles = false;
      if (message.hasOwnProperty(key)) {
         if ((key == 'month' || key == 'year' || key == 'description') || needTitlesInText)
            needTitles = true;
         temp_data[key] = parseTextToHTML(message[key], needTitles);
      }
      else {
         temp_data[key] = message[key];
      }
   }
   return temp_data;
}

// Форматирование полученных данных с сервера для отображения в приложении
function getFormattedDataMoonCalendar(message, needTitlesInText) {
   let temp_data = {};
   let needTitles;

   for (const year in message) {
      temp_data[year] = {};
      if (message.hasOwnProperty(year)) {
         for (const month in message[year]) {
            if (message[year].hasOwnProperty(month)) {
               needTitles = needTitlesInText ? true : false;
               temp_data[year][month] = {};
               temp_data[year][month]['description'] = needTitles ? parseTextToHTML(message[year][month], needTitles) : message[year][month];
               temp_data[year][month]['title'] = `${getMonthName(month)} ${year}`;
            }
         }
      }
   }
   return temp_data;
}

// Преобразовываем текст в HTML разметку
function parseTextToHTML(text, needTitles) {
   let paragraphs = text.split('\n\n');
   let html = paragraphs.map((paragraph, index) => {
      const lines = paragraph.split('\n');
      const firstLine = lines[0];
      const restOfLines = lines.slice(1).join('<br>');
      if (needTitles)
         return `<p><strong>${firstLine}</strong><br>${restOfLines}</p>`;
      else
         return `<p>${firstLine}<br>${restOfLines}</p>`;
   }).join('');
   return html;
}

export function getMonthName(monthNumber) {
   const months = [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь"
   ];

   // Проверка на корректность входных данных
   if (monthNumber >= 1 && monthNumber <= 12) {
      return months[monthNumber - 1];
   } else {
      return "Недопустимый номер месяца";
   }
}

export function getCurrentYear() {
   // Получаем текущую дату
   const currentDate = new Date();

   // Получаем текущий год
   const currentYear = currentDate.getFullYear();

   return currentYear;
}

export function getCurrentMonth() {
   // Получаем текущую дату
   const currentDate = new Date();

   // Получаем текущий год
   const currentMonth = currentDate.getMonth() + 1;

   return currentMonth;
}

export function formatZodiacsDateRange(dateStart, dateEnd) {
   // Разбиваем даты на части
   const startParts = dateStart.split('.');
   const endParts = dateEnd.split('.');

   // Преобразуем части в числа
   const startDay = parseInt(startParts[0]);
   const startMonth = parseInt(startParts[1]);
   const endDay = parseInt(endParts[0]);
   const endMonth = parseInt(endParts[1]);

   // Преобразуем месяц в текст
   const monthNames = [
      'января', 'февраля', 'марта',
      'апреля', 'мая', 'июня',
      'июля', 'августа', 'сентября',
      'октября', 'ноября', 'декабря'
   ];

   const startMonthText = monthNames[startMonth - 1];
   const endMonthText = monthNames[endMonth - 1];

   // Возвращаем отформатированный текст
   return `${startDay} ${startMonthText} - ${endDay} ${endMonthText}`;
}

export function getZodiacSignByDate(date, zodiacs) {
   if (date) {
      const month = date.getMonth() + 1;
      const day = date.getDate();

      for (const sign in zodiacs) {
         const { date_start, date_end } = zodiacs[sign];
         const [startDay, startMonth] = date_start.split('.').map(Number);
         const [endDay, endMonth] = date_end.split('.').map(Number);

         if (
            (month == startMonth && day >= startDay) ||
            (month == endMonth && day <= endDay)
         ) {
            return sign;
         }
      }
   }

   return null;
}

export function convertDateStringToDate(inputDateString) {
   if(isValidVKDate(inputDateString)) {
      const [day, month, year] = inputDateString.split('.').map(Number);
      const convertedDate = new Date(year, month - 1, day);
      return convertedDate;
   }
   else {
      return null;
   }
   
}

function isValidVKDate(date) {
   // Регулярное выражение для формата "DD.MM.YYYY"
   const dateFormatRegex = /^(\d{1,2}\.\d{1,2}\.\d{4})$/;

   // Проверяем, соответствует ли текст заданному формату
   return dateFormatRegex.test(date);
}