// Импортируем bridge из VK Bridge API
import bridge from '@vkontakte/vk-bridge';

export const VKWebAppAllowNotifications = async () => {
   bridge.send('VKWebAppAllowNotifications')
      .then((data) => {
         if (data.result) {
            console.log(data.result);
         } else {
            console.log(data.result);
         }
      })
      .catch((error) => {
         console.log(error);
      });
}

export const VKWebAppShare = async () => {
   bridge.send('VKWebAppShare', {
      link: 'https://vk.com/app51692231'
   })
      .then((data) => {
         if (data.result) {
            // Запись размещена
         }
      })
      .catch((error) => {
         // Ошибка
         console.log(error);
      });
}

export const VKWebAppShowWallPostBox = async (message) => {
   bridge.send('VKWebAppShowWallPostBox', {
      message: message,
      attachments: 'https://vk.com/app51692231'
   })
      .then((data) => {
         if (data.post_id) {
            console.log('Запись на стене размещна с ID', data.post_id);
         }
      })
      .catch((error) => {
         // Ошибка
         console.log(error);
      });
}

export const VKWebAppShowStoryBox = async (message) => {
   bridge.send('VKWebAppShowStoryBox', {
      background_type: 'image',
      locked: false,
      url: 'https://horoscope.mgk-it.ru/images/history_wallpaper.jpg',
      attachment: {
         text: 'Текст 123',
         type: 'photo',
         owner_id: 179057432,
         id: 457239373
      }
   })
      .then((data) => {
         if (data.code_data) {
            // Редактор историй открыт
            console.log(data);
         }
      })
      .catch((error) => {
         // Ошибка
         console.log(error);
      });
}

export const VKWebAppGetUserInfo = async (setVKUser) => {
   bridge.send('VKWebAppGetUserInfo')
      .then((data) => {
         if (data.id) {
            // Данные пользователя получены
            setVKUser(data);
         }
      })
      .catch((error) => {
         // Ошибка
         console.log(error);
      });
}
