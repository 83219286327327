import React, { useState, useEffect } from 'react';
import { Panel } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';

import Header from '../components/header/Header';
import Content from '../components/Content';
import Footer from '../components/footer/Footer';
import { ROUTES } from '../config';
import Back from './Back';
import Intro from './Intro';
import Choose from './Choose';
import Forecast from './Forecast';
import Compatibility from './Compatibility';
import AboutSign from './AboutSign';
import MoonCalendar from './MoonCalendar';

import { getFromLocalStorage, setToLocaleStorage, getFromVKStorage, setToVKStorage, getSVGIcon, createSvgFromCode } from '../components/app/getAndSetToStorage';
import { getZodiacData } from '../components/app/getServerData';
import Popup from '../components/Popup';
import SnackbarApp from '../components/SnackbarApp';
import SelectBirth from '../components/SelectBirth';
import { VKWebAppGetUserInfo } from '../components/app/VKBridgeFunctions';

const Main = ({ }) => {
	const [VKUser, setVKUser] = useState(null);



	useEffect(() => {
		if (VKUser)
			console.log(VKUser);
		else
			VKWebAppGetUserInfo(setVKUser);
	}, [VKUser]);


	const [activeContent, setActiveContent] = useState(ROUTES.BACK);
	const [selectedZodiac, setSelectedZodiac] = useState(null);
	const [zodiacLocalStorage, setZodiacLocalStorage] = useState(null);
	const [zodiacVKStorage, setZodiacVKStorage] = useState(null);
	const keyStorageSelectedZodiac = 'selectedZodiac';

	const [loading, setLoading] = useState(true);
	const [errorZodiacBD, setErrorZodiacBD] = useState(false);
	const [zodiacDataFromBD, setZodiacDataFromBD] = useState(null);

	const [forecastToday, setForecastToday] = useState('Не удалось получить прогноз с сервера');
	const [forecastTomorrow, setForecastTomorrow] = useState('Не удалось получить прогноз с сервера');
	const [forecastWeek, setForecastWeek] = useState('Не удалось получить прогноз с сервера');
	const [forecastMonth, setForecastMonth] = useState('Не удалось получить прогноз с сервера');
	const [forecastYear, setForecastYear] = useState('Не удалось получить прогноз с сервера');
	const [zodiacDescription, setZodiacDescription] = useState('Не удалось получить описание знака зодиака с сервера');

	// Функционал контроля статуса сети
	const [isOnline, setIsOnline] = useState(window.navigator.onLine);

	const setErrorDescription = () => {
		setForecastToday('Не удалось получить прогноз с сервера');
		setForecastTomorrow('Не удалось получить прогноз с сервера');
		setForecastWeek('Не удалось получить прогноз с сервера');
		setForecastMonth('Не удалось получить прогноз с сервера');
		setForecastYear('Не удалось получить прогноз с сервера');
		setZodiacDescription('Не удалось получить описание знака зодиака с сервера');
	}

	const [showPopupDateBirth, setShowPopupDateBirth] = useState(false);

	// Функционал получения и сохранения основного знака с подгрузкой данных с сервера
	useEffect(() => {
		if (selectedZodiac && (selectedZodiac != zodiacLocalStorage)) {
			setZodiacLocalStorage(selectedZodiac);
			setZodiacVKStorage(selectedZodiac);
			setToLocaleStorage(keyStorageSelectedZodiac, selectedZodiac);
			setToVKStorage(keyStorageSelectedZodiac, selectedZodiac);

			// Получаем данные о знаке зодиака с прогнозом
			getZodiacData(setLoading, setErrorZodiacBD, setErrorDescription, selectedZodiac, setForecastToday, setForecastTomorrow, setForecastWeek, setForecastMonth, setForecastYear, setZodiacDescription, setZodiacDataFromBD);

		}
		else if (selectedZodiac === null) {
			const zodiacLocal = getFromLocalStorage(keyStorageSelectedZodiac);
			if (zodiacLocal) {
				setSelectedZodiac(zodiacLocal);
				setZodiacLocalStorage(zodiacLocal);
				setZodiacVKStorage(zodiacLocal);
				setToVKStorage(keyStorageSelectedZodiac, zodiacLocal);

				getZodiacData(setLoading, setErrorZodiacBD, setErrorDescription, zodiacLocal, setForecastToday, setForecastTomorrow, setForecastWeek, setForecastMonth, setForecastYear, setZodiacDescription, setZodiacDataFromBD);
				changeContent(ROUTES.FORECAST);
			}
			else {
				getFromVKStorage(keyStorageSelectedZodiac, activeContent, changeContent, setSelectedZodiac, setZodiacLocalStorage, setZodiacVKStorage);
			}
		}
	}, [zodiacLocalStorage, selectedZodiac, isOnline]);

	useEffect(() => {
		if (selectedZodiac)
			console.log('Назначен основной знак: ' + selectedZodiac);
	}, [selectedZodiac]);

	// useEffect(() => {
	// 	if (zodiacDataFromBD)
	// 		console.log(zodiacDataFromBD);
	// }, [zodiacDataFromBD]);

	useEffect(() => {
		if (zodiacVKStorage)
			console.log('Zodiac VK Storage: ' + zodiacVKStorage);
	}, [zodiacVKStorage]);

	useEffect(() => {
		if (showPopupDateBirth)
			console.log('showPopupDateBirth: ' + showPopupDateBirth);
	}, [showPopupDateBirth]);

	const [showPopupNotificationSettings, setShowPopupNotificationSettings] = useState(false);

	useEffect(() => {
		const handleOnline = () => {
			setIsOnline(window.navigator.onLine);
		};

		const handleOffline = () => {
			setIsOnline(window.navigator.onLine);
		};

		console.log(`Статус онлайна: ${isOnline}`);
		if (isOnline && selectedZodiac && forecastToday == 'Не удалось получить прогноз с сервера') {
			getZodiacData(setLoading, setErrorZodiacBD, setErrorDescription, selectedZodiac, setForecastToday, setForecastTomorrow, setForecastWeek, setForecastMonth, setForecastYear, setZodiacDescription);
		}

		window.addEventListener('online', handleOnline);
		window.addEventListener('offline', handleOffline);

		return () => {
			window.removeEventListener('online', handleOnline);
			window.removeEventListener('offline', handleOffline);
		};
	}, [isOnline]);

	const changeContent = (route) => {
		setActiveContent(route);
		console.log(`Сменили контент на ${route}`);
	}

	const [svgZodiacIcons, setSvgZodiacIcons] = useState(null);
	const keyStorageSvgZodiacIcons = 'svgZodiacIcons';

	useEffect(() => {
		const fetchData = async () => {
			const filePath = '/images/zodiacs/icons/zodiac_icons_sprite.svg';
			try {
				const fileText = await getSVGIcon(filePath)
					.then((fileText) => {
						setSvgZodiacIcons(fileText);
						setToLocaleStorage(keyStorageSvgZodiacIcons, fileText);
					}).catch((error) => {
						console.log('Error', error);
					});

			} catch (error) {
				console.log('Error', error);
			}
		};
		if (!svgZodiacIcons) {
			const locacZodiac = getFromLocalStorage(keyStorageSvgZodiacIcons);
			if (locacZodiac) {
				setSvgZodiacIcons(locacZodiac);
			}
			else {
				fetchData(); // Вызываем асинхронную функцию
			}
		}

	}, [svgZodiacIcons]);


	const routes = {
		[ROUTES.BACK]:
			<Back />,
		[ROUTES.INTRO]:
			<Intro nextButtonClick={() => changeContent(ROUTES.CHOOSE)} />,
		[ROUTES.CHOOSE]:
			<Choose selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} nextButtonClick={() => changeContent(ROUTES.FORECAST)} changeContent={changeContent} svgZodiacIcons={svgZodiacIcons} />,
		[ROUTES.FORECAST]:
			<Forecast selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} loading={loading} forecastToday={forecastToday} forecastTomorrow={forecastTomorrow} forecastWeek={forecastWeek} forecastMonth={forecastMonth} forecastYear={forecastYear} changeContent={changeContent} svgZodiacIcons={svgZodiacIcons} zodiacDataFromBD={zodiacDataFromBD} />,
		[ROUTES.COMPATIBILITY]:
			<Compatibility selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} loading={loading} changeContent={changeContent} svgZodiacIcons={svgZodiacIcons} setErrorZodiacBD={setErrorZodiacBD} isOnline={isOnline} />,
		[ROUTES.ABOUTSIGN]:
			<AboutSign selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} loading={loading} showPopupDateBirth={showPopupDateBirth} setShowPopupDateBirth={setShowPopupDateBirth} zodiacDescription={zodiacDescription} changeContent={changeContent} />,
		[ROUTES.MOONCALENDAR]:
			<MoonCalendar activeContent={activeContent} setErrorZodiacBD={setErrorZodiacBD} isOnline={isOnline} />
	};

	const showHeaderAndFooter = activeContent !== ROUTES.INTRO && activeContent !== ROUTES.CHOOSE && activeContent !== ROUTES.BACK;

	return (
		<Panel id='main' centered={true} >
			<div className='body'>
				<div className='screen flex flex-col w-full relative'>
					{showHeaderAndFooter &&
						<Header activeContent={activeContent} changeContent={changeContent} selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} setShowPopupDateBirth={setShowPopupDateBirth} setShowPopupNotificationSettings={setShowPopupNotificationSettings} />
					}
					<Content content={routes[activeContent] || null} className='flex-grow' />
					{showHeaderAndFooter &&
						<Footer activeContent={activeContent} changeContent={changeContent} />
					}
				</div>
				<div className="noite"></div>
				<div className="constelacao"></div>
				<div className="chuvaMeteoro"></div>
				<div className="clouds-container"></div>
				{svgZodiacIcons && (
					createSvgFromCode(svgZodiacIcons, 'zodiacIcons', '')
				)}
				{errorZodiacBD & !isOnline && (<SnackbarApp setShowSnackbar={setErrorZodiacBD} text="Отсутствует подключение к интернету." />)}
				{errorZodiacBD & isOnline && (<SnackbarApp setShowSnackbar={setErrorZodiacBD} text="Не удалось подгрузить данные с сервера. Попробуйте снова." />)}
				{showPopupDateBirth && (<Popup setPopupShow={setShowPopupDateBirth} content={<SelectBirth selectedZodiac={selectedZodiac} setSelectedZodiac={setSelectedZodiac} changeContent={changeContent} setPopupShow={setShowPopupDateBirth} svgZodiacIcons={svgZodiacIcons} className='mt-1' />} />)}
				{showPopupNotificationSettings && (<Popup setPopupShow={setShowPopupNotificationSettings} content='123' />)}
			</div>
			<style>
				{`.vkuiPopper {z-index: 999 !important;}`}
			</style>
		</Panel >
	);
};
export default Main;