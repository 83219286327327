import React from 'react';
import { Spinner } from '@vkontakte/vkui';
import InfoBlock from '../InfoBlock';
import Zodiacs from '../zodiacs/Zodiacs';
import { Icon36Cancel } from '@vkontakte/icons';
import Btn from '../Button';

const PopupCompatibility = ({ loading, setPopupIsOpen, selectedZodiac, selectedZodiacCompatibility, compatibilityDescription }) => {
   const onClose = () => {
      setPopupIsOpen(false);
   }

   return (
      <div className="my-popup flex fixed w-full bg-black bg-opacity-70" >
         <div className='flex grow hover:cursor-pointer' onClick={() => onClose()} ></div>
         <div className="flex flex-col items-center screen w-full grow bg-black px-0">
            <div className="flex justify-between w-full h-10">
               <div
                  className="flex items-center cursor-pointer"
                  onClick={() => onClose()}
               >
                  <Icon36Cancel fill="#fff" />
               </div>
               <div className="flex text-base font-medium sm:text-lg md:text-xl items-center ">
               </div>

            </div>
            <h2 className='text-center text-white inline-block mb-5'>
               {Zodiacs[selectedZodiac].title} и {Zodiacs[selectedZodiacCompatibility].title}
            </h2>
            <InfoBlock className='description flex-1 mt-0' content={loading ? <Spinner size="large" /> : compatibilityDescription} isHtml={loading ? false : true} />
            <Btn text='Закрыть' className='mb-6' handleClick={onClose} />
         </div>
         <div className='flex grow hover:cursor-pointer'  onClick={() => onClose()} ></div>
      </div>
   )
}

export default PopupCompatibility;