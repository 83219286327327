import React, { useState, useEffect } from 'react';
import { Panel, Spinner, Div } from "@vkontakte/vkui";
import '@vkontakte/vkui/dist/vkui.css';;
import { getMoonCalendarData, getMonthName, getCurrentMonth, getCurrentYear } from '../components/app/getServerData';

import InfoBlock from "../components/InfoBlock";
import H1 from '../components/H1';
import H2Dropdown from '../components/H2Dropdown';

const MoonCalendar = ({ activeContent, setErrorZodiacBD, isOnline }) => {
  const year = getCurrentYear();
  const month = getCurrentMonth();
  const [moonCalendarYear, setMoonCalendarYear] = useState(year);
  const [moonCalendarMonth, setMoonCalendarMonth] = useState(month);
  const [moonCalendarDescription, setMoonCalendarDescription] = useState('Не удалось получить данные лунного календаря.');
  const [moonCalendarList, setMoonCalendarList] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const baseCalendarList = {
    [moonCalendarYear]: {
      [moonCalendarMonth]: {
        'description': moonCalendarDescription,
        'title': `${getMonthName(moonCalendarMonth)} ${moonCalendarYear}`
      }
    }
  };

  useEffect(() => {
    if (!moonCalendarList) {
      const moonCalendarListSessionStorage = sessionStorage.getItem('moonCalendarListSessionStorage');
      if (moonCalendarListSessionStorage) {
        setMoonCalendarList(JSON.parse(moonCalendarListSessionStorage));
      }
      else if(isOnline) {
        getMoonCalendarData(setLoading, setErrorZodiacBD, setMoonCalendarList);
      }
      else
        setErrorZodiacBD(true);
    }
    else if (moonCalendarList[moonCalendarYear] && moonCalendarList[moonCalendarYear][moonCalendarMonth]) {
      setMoonCalendarDescription(moonCalendarList[moonCalendarYear][moonCalendarMonth]['description']);
      sessionStorage.setItem('moonCalendarListSessionStorage', JSON.stringify(moonCalendarList));
      console.log(`Показали описание календаря стрижек для периода ${getMonthName(moonCalendarMonth)} ${moonCalendarYear}`);
    }
  }, [moonCalendarYear, moonCalendarMonth, moonCalendarList, isOnline]);

  return (
    <>
      <H1 className='mt-6 shadow-text' text='Лунный календарь стрижек' />
      <H2Dropdown className='shadow-text' blockName='moonСalendarDropdown' text={`${getMonthName(moonCalendarMonth)} ${moonCalendarYear}`} list={moonCalendarList !== null ? moonCalendarList : baseCalendarList} selectedData={[{ moonCalendarYear }, { moonCalendarMonth }]} setSelectedData={[{ setMoonCalendarYear }, { setMoonCalendarMonth }]} />
      <InfoBlock className='description flex-1 rounded-bl-none rounded-br-none' content={loading ? <Spinner size="large" /> : moonCalendarDescription} isHtml={loading ? false : true} activePanel={activeContent} />
    </>
  );
};
export default MoonCalendar;
