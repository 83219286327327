import React, { useState, useEffect } from 'react';
import { Spinner, ActionSheet, ActionSheetItem } from "@vkontakte/vkui";
import '@vkontakte/vkui/dist/vkui.css';

import Zodiacs from '../components/zodiacs/Zodiacs';
import ZodiacsItemForecastIllustrate from '../components/zodiacs/ZodiacsItemForecastIllustrate';
import InfoBlock from '../components/InfoBlock';
import Tabs from '../components/Tabs';
import H2 from '../components/H2';
import ShareButton from '../components/ShareButton';

import { getCurrentMonth, getMonthName, getCurrentYear } from '../components/app/getServerData';
import { VKWebAppShare } from '../components/app/VKBridgeFunctions';

const Forecast = ({ selectedZodiac, setSelectedZodiac, loading, forecastToday, forecastTomorrow, forecastWeek, forecastMonth, forecastYear, zodiacDataFromBD }) => {
	const currentMonth = getCurrentMonth();
	const currentMonthName = getMonthName(currentMonth);
	const currentYear = getCurrentYear();
	const nextYear = currentYear + 1;

	const monthTab = currentMonthName ? currentMonthName : 'Месяц';
	const yearTab = currentMonth > 10 ? `${nextYear} г` : `${currentYear} г`;

	const [activeTab, setActiveTab] = useState('Сегодня');
	const tabLabels = ['Сегодня', 'Завтра', 'Неделя', monthTab, yearTab];
	const tabContent = {
		Сегодня: forecastToday,
		Завтра: forecastTomorrow,
		Неделя: forecastWeek,
		[monthTab]: forecastMonth,
		[yearTab]: forecastYear,
	};

	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const [shareForecastText, setShareForecastText] = useState(null);
	useEffect(() => {
		try {
			const title_text = `Гороскоп для ${Zodiacs[selectedZodiac].title} на ${activeTab}`;
			switch (activeTab) {
				case 'Сегодня':
					setShareForecastText(`${title_text}.\n${zodiacDataFromBD['today']}`);
					break;
				case 'Завтра':
					setShareForecastText(`${title_text}.\n${zodiacDataFromBD['tomorrow']}`);
					break;
				case 'Неделя':
					setShareForecastText(`${title_text}.\n${zodiacDataFromBD['week']}`);
					break;
				case monthTab:
					setShareForecastText(`${title_text}.\n${zodiacDataFromBD['month']}`);
					break;
				case yearTab:
					setShareForecastText(`${title_text}.\n${zodiacDataFromBD['year']}`);
					break;
				default:
					setShareForecastText(null);
					break;
			}
		} catch (error) {

		}

	}, [forecastToday, forecastTomorrow, forecastWeek, forecastMonth, forecastYear, activeTab]);

	return (
		<>
			<ZodiacsItemForecastIllustrate className='' zodiac={Zodiacs[selectedZodiac]} />
			<H2 className='shadow-text' text={Zodiacs[selectedZodiac].title} selectedData={selectedZodiac} setSelectedData={setSelectedZodiac} />
			<Tabs
				tabLabels={tabLabels}
				activeTab={activeTab}
				onTabClick={handleTabClick}
				className='shadow-text'
			/>

			<InfoBlock className='description mt-1 flex-grow overflow-auto rounded-bl-none rounded-br-none'
				content={loading ? <Spinner size="large" /> : tabContent[activeTab]}
				elems={<ShareButton shareText={shareForecastText} />} isHtml={loading ? false : true}>
			</InfoBlock>


			<div className='relative'>
			</div>

		</>
	);
};
export default Forecast;
