import React from "react";
import { formatZodiacsDateRange } from "../app/getServerData";

const ZodiacDopInfo = ({ className, zodiac }) => {

  return (
    <div className={`flex items-center justify-center text-center text-stone-300 ${className ? className : ''}`}>
      {`${formatZodiacsDateRange(zodiac.date_start, zodiac.date_end)}. Стихия: ${zodiac.element}. `}
    </div>
  );
};

export default ZodiacDopInfo;
